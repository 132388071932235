<template>
  <nav class="l-gnav js-gnav">
    <header class="l-gnav_header">
      <h1>
        <picture>
          <source srcset="@/assets/img/gnav/logo_pc.png" media="(min-width: 1200px)"/>
          <img src="@/assets/img/gnav/logo.png" alt="Smart119" />
        </picture>
      </h1>
    </header>
    <div class="l-gnav_body">
      <!-- 医療機関/閲覧者 -->
      <ul class="l-gnav_list" v-if="this.role=='0'">
        <li class="l-gnav_item l-gnav_item--request"><router-link to="/">受入要請</router-link></li>
        <li class="l-gnav_item l-gnav_item--account"><router-link to="/">アカウント管理</router-link></li>
        <li class="l-gnav_item l-gnav_item--logout"><router-link to="/">ログアウト</router-link></li>
      </ul>
      <!-- 医療機関/管理者 -->
      <ul class="l-gnav_list" v-if="this.role=='1'">
        <li class="l-gnav_item l-gnav_item--request"><router-link to="/">受入要請</router-link></li>
        <li class="l-gnav_item l-gnav_item--past"><router-link to="/">過去の受入要請</router-link></li>
        <li class="l-gnav_item l-gnav_item--subject"><router-link to="/">科目設定</router-link></li>
        <li class="l-gnav_item l-gnav_item--demand"><router-link to="/">応需設定</router-link></li>
        <li class="l-gnav_item l-gnav_item--hospital"><router-link to="/">病院情報設定</router-link></li>
        <li class="l-gnav_item l-gnav_item--staff"><router-link to="/">職員管理</router-link></li>
        <li class="l-gnav_item l-gnav_item--account"><router-link to="/">アカウント管理</router-link></li>
        <li class="l-gnav_item l-gnav_item--logout"><router-link to="/">ログアウト</router-link></li>
      </ul>
      <!-- 消防/管理者 -->
      <ul class="l-gnav_list" v-if="this.role=='2'">
        <li class="l-gnav_item l-gnav_item--all"><router-link to="/">全事案一覧</router-link></li>
        <li class="l-gnav_item l-gnav_item--hospital"><router-link to="/">医療機関情報閲覧</router-link></li>
        <li class="l-gnav_item l-gnav_item--staff"><router-link to="/">職員管理</router-link></li>
        <li class="l-gnav_item l-gnav_item--account"><router-link to="/">アカウント管理</router-link></li>
        <li class="l-gnav_item l-gnav_item--logout"><router-link to="/">ログアウト</router-link></li>
      </ul>
    </div>
    <div class="l-gnav_trig js-gnavTrig"><span></span></div>
  </nav>
</template>

<script>
export default {
  name: 'GlobalNav',
  props: {
    role: String
  },
  // data() {},
  computed: {
  //  this.type = this.role;
  },
  mounted() {
    var gnav = document.querySelector(".js-gnav");
    var trig = document.querySelector(".js-gnavTrig");
    var toggleNav = () => {
      gnav.classList.toggle("is-show");
    };
    trig.addEventListener("click", toggleNav);
    trig.addEventListener("tap", toggleNav);
  }
}
</script>
