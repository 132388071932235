import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import global from './mixin/global'
import axios from 'axios';
require('@/assets/scss/style.scss');


// アプリケーション生成
const app = createApp(App);

// グローバルメソッド定義
app.config.globalProperties.$isLocal = global.isLocal;
app.config.globalProperties.$axios = axios;


// アプリケーションのマウント
app.use(store).use(router).mount('#app');