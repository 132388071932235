<template>
  <GlobalNav v-bind:role="role" />
  <router-view/>
</template>
<script>
import GlobalNav from '../components/GlobalNav.vue';

export default {
  name: 'HomeView',
  components: {
    GlobalNav
  },
  computed: {
    role() {
      return this.$store.state.role;
    }
  }
}
</script>
